.arrow {
    /*width: 100%;*/
    align-items: center;
    justify-content: end;
}

.triangle-left {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-right: 50px solid #2b2838;
    border-bottom: 30px solid transparent;
    /*backdrop-filter: blur(10px);*/
}

.arrow-line {
    align-items: center;
    height: 30px;
    width: 74%;
    background-color: var(--panel-bg);
    border-radius: 0rem 0.4rem 0.4rem 0rem;
    justify-content: center;
}

.arrow-date {
    font-size: 0.8rem;
    width: 25%;
    font-weight: bold;
    margin: 0rem 0.5rem;
}