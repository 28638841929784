.language-section {
    align-self: end;
}

.language-button {
    background-color: transparent;
    border: none;
    color: var(--panel-border);
    font-weight: bold;
}

.language-button:hover {
    color: var(--text-highlight);
}

.language-button:visited {
    color: var(--text-highlight);
}

.language-button-spacer {
    background-color: var(--panel-border);
    width: 0.1rem;
    height: 70%;
    align-self: center;
}

@media (max-width: 768px) {
    .language-section {
        align-self: center;
        gap: 0.2rem;
    }

    .language-button {
        font-size: 1.2rem;
        background-color: var(--panel-bg);
        border: none;
        color: var(--panel-border);
        font-weight: bold;
        border-radius: 0.2rem;
        padding: 1rem;
        width: 30vw;
    }

    .language-button:hover {
        color: var(--text-highlight);
    }

    .language-button:visited {
        color: var(--text-highlight);
    }

}