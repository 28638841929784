.bread-crumb {
    color: var(--text);
}

.bread-crumb-title {
    /*font-family: "Bauhaus 93";*/
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--text-highlight);

}

.bread-crumb:hover {
    color: var(--highlight);
}