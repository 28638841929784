body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --white: white;
  --background: #22202d;
  --text: #635985;
  --text-highlight: #d4c9f8;
  --panel-bg: #b899f91c;
  --panel-border: #8476b3;
  --panel-text: #988ebc;
  --highlight: skyblue;
  --tu-red: #be1e3c;
  --avatar-pink: rgb(171 118 148);
}
