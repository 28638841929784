.top-menu {
    background-color: #63598533;
    /*border-bottom: var(--text-highlight);*/
    border-bottom-color: #b29cfb33;
    border-bottom-style: solid;
    border-width: 0.1rem;
    backdrop-filter: blur(0.5rem);
    justify-content: center;
    height: 8vh;
    width: 100%;
    position: fixed;
    z-index: 9999;
    gap: 0.5rem;
}

.button-border {
    border-color: #b29cfb33;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 1rem;
    padding: 0.6rem;
}

.menu-button {
    border-radius: 1rem;
    padding: 0.6rem;
    background-color: #b29cfb33;
    border-style: none;
    color: transparent;
    vertical-align: middle;
}

.menu-icon {
    color: var(--white);
    vertical-align: middle;
}