@keyframes fade-in {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes pulsate {

    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}