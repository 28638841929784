.mobile-contact-button {
    background-color: var(--panel-bg);
    color: var(--white);
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 1rem;
    border-style: none;
    font-weight: bold;
    font-size: 1.2rem;
    /*outline: 1px solid var(--panel-border);*/
    text-align: center;

    opacity: 0;
    animation-name: fade-in;
    animation-duration: 2s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}