.app {
    text-align: center;
    color: var(--text);

    gap: 2rem;
    position: absolute;
}

html {
    background-color: var(--background);
}

.app-content {
    gap: 4rem;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

a {
    text-decoration: none;
  display: contents;
}

.section-title {
    font-weight: bold;
    font-size: 3rem;
    color: var(--panel-bg);
    /*margin: 2rem;*/
}

.section-container {
    width: 100%;
    /*gap: 1.4rem;*/
    /*height: 100vh;*/
    /*align-items: center*/
}

.my-shape {
    width: 60rem;
    height: 60rem;
    background-color: rgba(127, 255, 212, 0.27);
    border-radius: 50rem;
    margin: 6rem;
}

.footer-container {
    padding: 5rem 0rem 5rem 0rem;
}

@media (max-width: 768px) {
    .app-content {
        gap: 0rem;
    }

    .section-title {
        font-weight: bold;
        font-size: 2rem;
        color: var(--panel-bg);
        margin: 2rem 1rem 1rem 1rem;
    }

    .footer-container {
        font-size: 0.6rem;
        background-color: #16141d;
        padding: 5rem 0rem 5rem 0rem;
    }
}