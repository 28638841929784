.home-container {
    text-align: left;
    width: 80%;
    align-self: center;
    gap: 3rem;
    /*min-height: 100vh;*/
}

.home-component {
    margin-top: 0.2rem;
}

.greeting {
    margin-top: 12rem;
    font-weight: bold;
    font-size: 4rem;
    color: var(--text-highlight);

    animation-name: fade-in;
    animation-duration: 4s;
}

.sub-text {
    text-align: left;
    font-size: 2.2rem;

    gap: 0.4rem;
    align-items: center;

    animation-name: fade-in;
    animation-duration: 4s;
}

.info-text {
    line-height: 1.5;
    font-size: 1.4rem;
    text-align: left;
    width: 80%;
    margin-top: 2rem;

    opacity: 0;
    animation-name: fade-in;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

.tu-bs {
    color: var(--tu-red);
    white-space: nowrap;
    font-weight: bold;
}

.tu-bs:hover {
    color: var(--white);
}

@media (max-width: 768px) {

    .home-container {
        text-align: left;
        width: 90%;
        align-self: center;
        gap: 3rem;
        min-height: 100vh;
        margin-top: 5rem;
    }


    .greeting {
        font-weight: bold;
        margin-top: 3rem;

        font-size: 3rem;
        color: var(--text-highlight);

        animation-name: fade-in;
        animation-duration: 4s;
    }

    .landing-text {
        text-align: left;
        font-size: 1.8rem;

        animation-name: fade-in;
        animation-duration: 4s;
        margin-bottom: 3rem;

    }

    .home-top-spacer {
        height: 5vh;
    }

    .home-middle-spacer {
        height: 2vh;
    }

    .info-text {
        line-height: 1;
        /*font-family: Source Code Pro, monospace, sans-serif;*/
        font-size: 1rem;
        text-align: left;
        width: 90%;

        opacity: 0;
        animation-name: fade-in;
        animation-duration: 2s;
        animation-delay: 1s;
        animation-fill-mode: forwards;

        /*display: flex;*/
        /*flex-direction: column;*/
        /*gap: 0.6rem;*/

        border-style: none;
        border-radius: 1rem;
        border-width: 1px;
        /*border-color: var(--panel-border);*/
        background-color: #1d1a25;
        padding: 1rem;
        align-self: center;
    }

    .tu-bs {
        color: var(--tu-red);
        white-space: normal;
        font-weight: bold;
    }
}

/*.typewriter h1 {*/
/*    overflow: hidden; !* Ensures the content is not revealed until the animation *!*/
/*    border-right: .15em solid orange; !* The typwriter cursor *!*/
/*    white-space: nowrap; !* Keeps the content on a single line *!*/
/*    margin: 0 auto; !* Gives that scrolling effect as the typing happens *!*/
/*    letter-spacing: .15em; !* Adjust as needed *!*/
/*    animation:*/
/*            typing 3.5s steps(40, end),*/
/*            blink-caret .75s step-end infinite;*/
/*}*/

/*!* The typing effect *!*/
/*@keyframes typing {*/
/*    from { width: 0 }*/
/*    to { width: 100% }*/
/*}*/

/*!* The typewriter cursor effect *!*/
/*@keyframes blink-caret {*/
/*    from, to { border-color: transparent }*/
/*    50% { border-color: orange; }*/
/*}*/